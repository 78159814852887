.ticTacToeContainer {
    display: grid;
    grid-template-columns: auto 300px auto;

    .ticTacToe {

        .gameTitle {
            margin: 20px 0px;
            text-align: center;
        }

        .gameBoard {
            display: grid;
            grid-template-rows: 1fr 1fr 1fr;
            min-height: 300px;
            width: 100%;

            .boardRow {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;

                &:nth-child(2) {
                    border-bottom: 1px solid #000;
                    border-top: 1px solid #000;
                }

                .boardCell {
                    cursor: pointer;
                    font-family: cursive, sans-serif;
                    font-size: 100px;
                    line-height: 95px;
                    position: relative;
                    text-align: center;

                    &:nth-child(2) {
                        border-left: 1px solid #000;
                        border-right: 1px solid #000;
                    }

                    &.X {
                        color: #f00;
                    }

                    &.O {
                        color: #00f;
                    }
                }
            }
        }

        .gameMessage {
            margin: 10px 0px;
            text-align: center;
        }
    }
}