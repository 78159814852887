.minesweeperContainer {
    display: grid;

    .minesweeper {

        .gameTitle {
            margin: 20px 0px;
            text-align: center;
        }

        .gameBoard {
            min-height: 300px;
            width: 100%;

            .boardRow {
                display: grid;
                grid-gap: 1px;
                margin-bottom: 1px;

                .boardCell {
                    cursor: default;
                    line-height: 28px;
                    // height: 30px;
                    height: 29px;
                    position: relative;
                    text-align: center;
                    // width: 30px;
                    width: 29px;

                    &.hidden::after {
                        cursor: pointer;
                        content: '';
                        position: absolute;
                        background-color: #ddd;
                        top: 0px;
                        left: 0px;
                        bottom: 0px;
                        right: 0px;
                        z-index: 1;
                    }

                    // colors from google's minesweeper
                    // &.hidden.lightSquare::after {
                    //     background-color: #b3d665;
                    // }
                    // &.hidden.darkSquare::after {
                    //     background-color: #acd05e;
                    // }
                    // &:not(.hidden).lightSquare {
                    //     background-color: #dfc3a3;
                    // }
                    // &:not(.hidden).darkSquare {
                    //     background-color: #d2b99d;
                    // }

                    &.cell1 { color: blue; }
                    &.cell2 { color: green; }
                    &.cell3 { color: red; }
                    &.cell4 { color: darkblue; }
                    &.cell5 { color: brown; }
                    &.cell6 { color: cyan; }
                    &.cell7 { color: black; }
                    &.cell8 { color: grey; }

                    .flag {
                        cursor: pointer;
                        color: #f00;
                        position: absolute;
                        top: 6px;
                        left: 6px;
                        z-index: 2;
                    }
                }
            }

            &.won .boardCell.hidden::after {
                background-color: rgba(200, 200, 200, 0.6) !important;
            }
        }

        .gameInfoContainer {
            display: grid;
            grid-template-columns: 1fr 80px 60px;

            .difficultySelect {
                margin-top: 15px;
            }

            & > div {
                padding-top: 20px;
                text-align: center;
            }
        }

        .winLossCounter {
            margin-bottom: 10px;
            text-align: center;
        }

        .gameMessage {
            margin: 10px 0px;
            text-align: center;
        }
    }
}