$squareHeight: 70px; // temp, while coding, real is 100px
// $squareHeight: 100px;

$squareWidth: 70px; // temp, while coding, real is 100px
// $squareWidth: 100px;

.chessContainer {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: calc(100vh - 105px) 250px;
    // height: calc(100% - 80px);
    justify-content: center;
    padding: 20px 0px 0px;
    // position: absolute;
    // width: calc(100% - 40px);

    .chessMenu {
        // position: absolute;
        // left: 50%;
        // top: 20px;
        // transform: translateX(-50%);
        grid-column: span 2;
        justify-self: center;
        width: 500px;

        .welcomeMessage {
            margin: 0px 0px 10px;
            text-align: center;
        }
    }

    .chessBoardContainer {

        .chessBoard {
            aspect-ratio: 1;
            // height: 100%;
            min-height: 300px;
            position: relative;
            // width: 100%;

            .boardRow {
                display: grid;
                grid-gap: 0px;
                grid-template-columns: repeat(8, 1fr);
                height: calc(100% / 8);

                .boardSquare {
                    position: relative;
                    text-align: center;

                    &.lightSquare {
                        background-color: #eee;
                    }

                    &.darkSquare {
                        background-color: #ccc;
                    }

                    &.selected {
                        background-color: #84bc84;
                    }

                    &.possibleMove {
                        background-color: #a3c4a3;
                    }

                    .squareName {
                        color: rgba(0, 0, 0, 0.3);
                        height: 20px;
                        left: 2px;
                        line-height: 20px;
                        position: absolute;
                        top: 0px;
                        width: 20px;
                    }
                }
            }

            .promotionChoiceDiv {
                cursor: pointer;
                grid-template-columns: repeat(4, 1fr);
                grid-template-rows: 100%;
                position: absolute;
                transform: translateX(-37.5%);
                width: 50%;
                z-index: 5;

                .boardSquare:hover {
                    background-color: #84bc84;
                }

                img {
                    height: 70%;
                    margin-top: 15%;
                }
            }

            .gamePiece {
                cursor: default;
                height: calc(100% / 8);
                position: absolute;
                transition: top .5s, left .5s;
                width: calc(100% / 8);

                img {
                    position: absolute;
                    left: 50%;
                    height: 70%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .rank1 { top: calc(100% / 8) * 7; }
            .rank2 { top: calc(100% / 8) * 6; }
            .rank3 { top: calc(100% / 8) * 5; }
            .rank4 { top: calc(100% / 8) * 4; }
            .rank5 { top: calc(100% / 8) * 3; }
            .rank6 { top: calc(100% / 8) * 2; }
            .rank7 { top: calc(100% / 8) * 1; }
            .rank8 { top: calc(100% / 8) * 0; }

            .fileA { left: calc(100% / 8) * 0; }
            .fileB { left: calc(100% / 8) * 1; }
            .fileC { left: calc(100% / 8) * 2; }
            .fileD { left: calc(100% / 8) * 3; }
            .fileE { left: calc(100% / 8) * 4; }
            .fileF { left: calc(100% / 8) * 5; }
            .fileG { left: calc(100% / 8) * 6; }
            .fileH { left: calc(100% / 8) * 7; }

            &.reversed {
                .rank1 { top: calc(100% / 8) * 0; }
                .rank2 { top: calc(100% / 8) * 1; }
                .rank3 { top: calc(100% / 8) * 2; }
                .rank4 { top: calc(100% / 8) * 3; }
                .rank5 { top: calc(100% / 8) * 4; }
                .rank6 { top: calc(100% / 8) * 5; }
                .rank7 { top: calc(100% / 8) * 6; }
                .rank8 { top: calc(100% / 8) * 7; }

                .fileA { left: calc(100% / 8) * 7; }
                .fileB { left: calc(100% / 8) * 6; }
                .fileC { left: calc(100% / 8) * 5; }
                .fileD { left: calc(100% / 8) * 4; }
                .fileE { left: calc(100% / 8) * 3; }
                .fileF { left: calc(100% / 8) * 2; }
                .fileG { left: calc(100% / 8) * 1; }
                .fileH { left: calc(100% / 8) * 0; }
            }
        }
    }

    .gameInfoContainer {

        & > div {
            margin-bottom: 10px;
            text-align: center;
        }

        .myColor, .turnIndicator, .checkStatus, .checkMateStatus {
            border: 2px solid #000;
            border-radius: 18px;
            height: 20px;
            line-height: 20px;
            padding: 6px;

            &.black {
                color: #fff;
                background-color: #000;
            }
        }

        .whiteCapturedPieces, .blackCapturedPieces {
            text-align: left;
            width: 250px;

            .gamePiece {
                display: inline-block;
                height: 50px;
                text-align: center;
                width: 50px;

                img {
                    height: 90%;
                    margin: 5%;
                }
            }
        }
    }
}