.home {
    display: inline-block;
    min-height: 100vh;
    // position: fixed;
    width: 100%;

    .dirList, #colorList {
        padding: 5px;
        background-color: #fff;
        border: #ccc solid 1px;

        span, a {
            cursor: pointer;
            display: block;
            margin: 10px;
            background-color: #eee;
            padding: 5px;
            color: #000;
            text-decoration: none;

            &:hover {
                background-color: #ccc;
            }
        }
    }

    #colorList span, body {
        transition: background-color 1s;
    }

    .homeContentContainer {
        // height: calc(100% - 40px);
        // left: 0px;
        margin-top: 40px;
        overflow: auto;
        // position: absolute;
        // top: 40px;
        transition: left .5s, width .5s;
        width: 100%;

        .welcomeContainer {
            // left: 50%;
            // position: absolute;
            // top: 50%;
            // transform: translate(-50%, -50%);

            .layoutS &, .layoutXS & {
                left: unset;
                position: relative;
                top: unset;
                transform: unset;
            }
            .layoutS & {
                margin: 15px calc(5vw - 7.5px);
            }

            .layoutXS & {
                margin: 15px 5vw;
            }

            .welcomeMessage {
                margin: 10px 0px;
                text-align: center;

                p {
                    margin: 10px 0px;
                }
            }

            .homeItemsContainer {
                display: grid;
                grid-gap: 15px;
                justify-content: center;

                .homeItem {
                    // aspect-ratio: $homeItemAspectRatio / 1;
                    background-color: #fff;
                    border-radius: 15px;
                    cursor: pointer;
                    display: grid;
                    grid-gap: 8px 5px;
                    grid-template-columns: 1fr auto;
                    grid-template-rows: auto 1fr;
                    padding: 10px;
                    text-align: center;
                    transition: transform 0.5s;

                    &:hover {
                        transform: scale(1.05);
                    }

                    .itemDescription {
                        color: #888;
                        font-size: 12px;
                        text-align: left;
                    }

                    .homeItemImage {
                        grid-row: span 2;
                    }
                }

                .layoutXL &, .layoutL & {
                    grid-template-columns: repeat(4, 20vw);

                    .homeItemImage { height: calc((20vw - 20px) / 2.5); }
                }

                .layoutM & {
                    grid-template-columns: repeat(3, 25vw);

                    .homeItemImage { height: calc((25vw - 20px) / 2.5); }
                }

                .layoutS & {
                    grid-template-columns: repeat(2, 45vw);

                    .homeItemImage { height: calc((45vw - 20px) / 2.5); }
                }

                .layoutXS & {
                    grid-template-columns: repeat(1, 90vw);

                    .homeItem {
                        // aspect-ratio: 2.5 / 1;
                        &:hover { transform: scale(1); }
                    }
                    .homeItemImage { height: calc((80vw - 20px) / 2.5); }
                }
            }
        }
    }

    .aboutMeFooter {
        color: #000;
        margin: 15px 0px 10px;
        opacity: 0.3;
        text-align: center;
        transition: opacity 0.3s;

        &:hover {
            opacity: 0.8;
        }
    }
}
