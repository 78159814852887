.wordEvolutionContainer {
    width: 90%;
    max-width: 500px;
    margin: 20px auto;

    #word {
        width: 100%;
        padding: 5px;
        font-size: 16px;
        margin: 10px 0px;
    }
    #goButton {
        background-color: #4CAF50;
        color: white;
        padding: 10px;
        border: none;
        cursor: pointer;
        width: 100%;
        transition: box-shadow .3s;
        font-size: 16px;
        margin: 10px 0px;

        &:hover {
            box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
        }
    }

    .radioContainer {
        cursor: pointer;
        width: 100%;
        display: inline-block;
        position: relative;
        margin: 2px 0px;

        .radioButton {
            width: 16px;
            height: 16px;
            border: 3px solid #999;
            border-radius: 100%;
            position: relative;
            cursor: pointer;
            transition: border-color .3s;

            .radioInside {
                width: 12px;
                height: 12px;
                background-color: #4CAF50;
                border-radius: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 0;
                transition: opacity .3s;
            }

            &.checked {
                border-color: #4CAF50;

                .radioInside {
                    opacity: 1;
                }
            }
        }

        .radioText {
            top: 2px;
            left: 25px;
            width: calc(100% - 25px);
            position: absolute;
            font-family: sans-serif;
        }
    }

    #evolution {
        font-family: monospace;
        font-size: 14px;
    }
}