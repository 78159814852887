.gameOfLifeContainer {
    display: grid;
    grid-template-columns: 1fr 340px;
    height: calc(100vh - 84px);
    justify-content: center;
    position: relative;

    .gameOfLife {
        justify-self: center;
        width: calc((100vh - 135px) * 1.33);
    }

    .worldTitle {
        height: 20px;
        margin: 15px 0px;
        text-align: center;
    }

    .world {
        display: grid;
        grid-auto-rows: 1fr;
        grid-gap: 1px;
        height: calc(100% - 96px);
        // height: calc(100vh - 110px);
        // margin-bottom: 20px;

        .worldRow {
            display: grid;
            grid-gap: 1px;

            .worldCell {
                cursor: default;
                position: relative;
                text-align: center;

                &.cell0 { background-color: white; }
                &.cell1 { background-color: black; }
            }
        }
    }

    .gameOfLife.paused {
        .worldCell {
            cursor: pointer !important;
        }
    }

    .worldInfoContainer {
        height: calc(100vh - 80px);
        min-width: 300px;
        overflow: auto;
        padding: 20px;

        .patternsList {
            .pattern {
                // display: inline-block;

                .world {
                    cursor: pointer;

                    .worldCell {
                        cursor: pointer !important;
                    }
                }
            }
        }
    }
}