.brailleContainer {
    position: relative;
    height: calc(100vh - 40px);

    .tabs {
        position: absolute;
        top: 10px;
        left: 10px;
        width: calc(100% - 22px);
        height: 29px;
        border: 1px solid #bbb;
        border-bottom: none;
        font-size: 18px;
        line-height: 18px;

        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1px;
        background-color: #bbb;

        span {
            position: relative;
            // display: inline-block;
            height: 29px;
            float: left;

            &.selected {
                border-bottom: none;
                background-color: #fff;
                height: 29px;
            }

            &:not(.selected) {
                border-bottom: 1px solid #bbb;
                background-color: #eee;
                height: 28px;
            }

            div {
                width: 100%;
                display: inline-block;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: center;
                padding: 6px 0px;
            }
        }
    }


    #main, #main2, .mainInput {
        position: absolute;
        top: 40px;
        left: 10px;
    }
    #main, #main2 {
        width: calc(100% - 22px);
        height: calc(100% - 52px);
        border: 1px solid #bbb;
        border-top: none;
        background-color: #fff;
    }
    .mainInput {
        width: calc(100% - 20px);
        height: calc(100% - 50px);
        // z-index: 100;
        padding: 0px;
        background-color: transparent;
        border-width: 0px;
        font-size: 12px;
        color: transparent;
        outline-style: none;
        opacity: 0;
    }

    #main2 {
        display: grid;
        grid-template-columns: 10% 1fr 1fr 10%;
    }

    /*--------------------------------------------Letters---------------------------------------------*/

    .letterBox {
        float: left;
        width: 30px;
        height: 60px;
        margin: 5px;
        position: relative;
        transition: background-color .5s;

        &.highlightedLetter {
            background-color: #ff0;
        }
    }

    .dot1, .dot0 {
        border-radius: 100%;
        margin: 5px;
        float: left;
    }

    .dot1 {
        width: 5px;
        height: 5px;
        background-color: #000;
    }

    .dot0 {
        width: 3px;
        height: 3px;
        border: 1px solid #000;
    }

    .letter {
        width: 100%;
        height: 15px;
        text-align: center;
        bottom: 0px;
        position: absolute;
    }

    .bigdot1, .bigdot0 {
        border-radius: 100%;
        margin: 25px;
        float: left;
    }

    .bigdot1 {
        width: 50px;
        height: 50px;
        background-color: #000;
    }

    .bigdot0 {
        width: 46px;
        height: 46px;
        border: 2px solid #000;
    }

    #submit {
        width: 200px;
        height: 30px;
        float: left;
        background-color: #0a0;
        color: #fff;
        font-size: 30px;
        line-height: 30px;
        text-align: center;
        font-family: 'Avenir';
        padding: 10px 0px;
        cursor: pointer;
    }
}