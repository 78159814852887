$gridGap: 12px;
$boxWidth: calc(25% - ($gridGap * 5 / 4));

@keyframes newSquarePop {
    0%   { transform: scale(1); }
    50%  { transform: scale(1.1); }
    100% { transform: scale(1); }
}

.container2048 {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: auto 1fr;

    .isMobile & {
        min-height: calc(100vh - 170px);
    }

    .gameHeader {
        grid-column: 2;
        margin: 20px 20px 15px;
        text-align: center;
    }

    .startGameButton {
        grid-column: 2;
        margin: 0px auto;
        width: 300px;
    }

    .gameBoard {
        aspect-ratio: 1;
        background-color: #ddd;
        border-radius: 20px;
        display: grid;
        grid-column: 2;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: $gridGap;
        max-height: 600px;
        max-width: 600px;
        overflow: hidden;
        padding: $gridGap;
        position: relative;
        // width: calc(100vh - 100px);

        .layoutHor & {
            height: calc(100vh - 140px);
        }

        .layoutVer & {
            width: calc(100vw - 50px);
        }

        .emptySpace {
            background-color: #fff;
            border-radius: 12px;
        }

        .numberSpace {
            border-radius: 12px;
            color: #fff;
            height: $boxWidth;
            position: absolute;
            width: $boxWidth;
            transition: top 0.1s, left 0.1s;
            transition-timing-function: linear;

            &.popAnimation {
                animation-name: newSquarePop;
                animation-duration: 0.2s;
            }

            &.row0 { top: $gridGap; }
            &.row1 { top: calc($gridGap + (($boxWidth + $gridGap) * 1)); }
            &.row2 { top: calc($gridGap + (($boxWidth + $gridGap) * 2)); }
            &.row3 { top: calc($gridGap + (($boxWidth + $gridGap) * 3)); }

            &.col0 { left: $gridGap; }
            &.col1 { left: calc($gridGap + (($boxWidth + $gridGap) * 1)); }
            &.col2 { left: calc($gridGap + (($boxWidth + $gridGap) * 2)); }
            &.col3 { left: calc($gridGap + (($boxWidth + $gridGap) * 3)); }

            &.number0 { background-color: #ffffff; } // White
            &.number2 { background-color: #fce94f; } // Butter 1
            &.number4 { background-color: #8ae234; } // Chameleon 1
            &.number8 { background-color: #fcaf3e; } // Orange 1
            &.number16 { background-color: #729fcf; } // Sky blue 1
            &.number32 { background-color: #ad7fa8; } // Plum 1
            &.number64 { background-color: #c17d11; } // Chocolate 2
            &.number128 { background-color: #ef2929; } // Scarlet red 1
            &.number256 { background-color: #c4a000; } // Butter 3
            &.number512 { background-color: #4e9a06; } // Chameleon 3
            &.number1024 { background-color: #ce5c00; } // Orange 3
            &.number2048 { background-color: #204a87; } // Sky blue 3

            &.number4096 { background-color: #712087; } // purple
            &.number8192 { background-color: #876005; } // brown
            &.number16384 { background-color: #841010; } // red
            &.number32768 { background-color: #849004; } // yellow
            &.number65536 { background-color: #148932; } // green
            &.number131072 { background-color: #c98425; } // orange

            .numberText {
                font-size: 32px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .endedScreen {
            background-color: #fff7;
            height: 100%;
            opacity: 0;
            position: absolute;
            text-align: center;
            transition: opacity 0.5s;
            width: 100%;

            &.endScreenVisible {
                opacity: 1;
            }

            & > div {
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 50%;

                div {
                    margin: 10px 0px;
                }
            }
        }
    }
}